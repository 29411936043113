import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiService: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  createProject(formData: any): Observable<any> {
    let path = `${this.baseUrl}/project`;
    return this.apiService.post(path, formData);
  }

  createProjectResource(formData: any): Observable<any> {
    let path = `${this.baseUrl}/project/resource`;
    return this.apiService.post(path, formData);
  }

  getProjectResource(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    projectId: string,
    accountId?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/project/resources/list?projectId=${projectId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountID=${accountId}`;
    }

    return this.apiService.get(path);
  }

  getProjectList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    accountId?: string,
    costModal?: string,
    status?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/project/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (costModal) {
      path += `&costModal=${costModal}`;
    }

    if (accountId) {
      path += `&accountID=${accountId}`;
    }

    if (status) {
      path += `&status=${status}`;
    }

    return this.apiService.get(path);
  }

  getProjectListCustomer(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    costModal?: string,
    accountId?: string,
    status?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/customer/project/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (costModal) {
      path += `&costModal=${costModal}`;
    }

    if (accountId) {
      path += `&accountID=${accountId}`;
    }

    if (status) {
      path += `&status=${status}`;
    }

    return this.apiService.get(path);
  }

  getProjectById(id: string): Observable<any> {
    const path = `${this.baseUrl}/project/${id}`;
    return this.apiService.get(path);
  }

  getProjectListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): Observable<any> {
    const path = `${this.baseUrl}/project/list?accountID=${accountId}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiService.get(path);
  }

  updateProject(projectData: any): Observable<any> {
    const url = `${this.baseUrl}/project`;
    return this.apiService.put(url, projectData).pipe(
      catchError((error: any) => {
        console.error('Error updating project:', error);
        throw error;
      })
    );
  }

  updateProjectResource(projectData: any): Observable<any> {
    const url = `${this.baseUrl}/project/resource`;
    return this.apiService.put(url, projectData).pipe(
      catchError((error: any) => {
        console.error('Error updating project resource:', error);
        throw error;
      })
    );
  }

  getProjectByAccountId(accountId: string): Observable<any> {
    const path = `${this.baseUrl}/project/list/${accountId}`;
    return this.apiService.get(path);
  }

  deleteProject(projectId: string): Observable<any> {
    const path = `${this.baseUrl}/project/${projectId}`;
    return this.apiService.delete(path);
  }

  deleteMileStones(milestoneId: string): Observable<any> {
    const path = `${this.baseUrl}/project/milestone/${milestoneId}`;
    return this.apiService.delete(path);
  }

  deleteProjectResource(projectResourceID: string): Observable<any> {
    const path = `${this.baseUrl}/project/resource/${projectResourceID}`;
    return this.apiService.delete(path);
  }

  getProjectByCostCentre(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    cost_CentreID: string
  ): Observable<any> {
    const path = `${this.baseUrl}/project/list?cost_centerID=${cost_CentreID}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiService.get(path);
  }

  getProjectByResourceId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    resourceID: string,
    accountId?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/project/list?resourceID=${resourceID}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountId=${accountId}`;
    }
    return this.apiService.get(path);
  }

  getCustomerProjectByResourceId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    resourceID: string,
    accountId?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/customer/project/list?resourceID=${resourceID}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountId) {
      path += `&accountID=${accountId}`;
    }
    return this.apiService.get(path);
  }

  getActivityLogs(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/activity-logs?module=project&module=project_resource&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiService.get(path);
  }

  addMilestone(formData: any) {
    const path = `${this.baseUrl}/invoice/milestone`;
    return this.apiService.post(path, formData).pipe(
      catchError((error) => {
        console.error('Error in addMilestone API call:', error);
        return throwError(() => error);
      })
    );
  }

  updateMilestone(data: any): Observable<any> {
    const path = `${this.baseUrl}/invoice/milestone`;
    return this.apiService.put(path, data);
  }

  generateDailyAndHourlyProjectInvoice(invoiceData: any): Observable<any> {
    const path = `${this.baseUrl}/invoice/project`;
    return this.apiService.post(path, invoiceData);
  }

  modifyDailyAndHourlyProjectInvoice(invoiceData: any): Observable<any> {
    const path = `${this.baseUrl}/invoice/project`;
    return this.apiService.put(path, invoiceData);
  }

  generateFixedProjectInvoice(invoiceData: any) {
    const path = `${this.baseUrl}/invoice/project`;
    return this.apiService.post(path, invoiceData);
  }

  modifyFixedProjectInvoice(invoiceData: any) {
    const path = `${this.baseUrl}/invoice/project`;
    return this.apiService.put(path, invoiceData);
  }
}
